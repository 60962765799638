import { AxiosResponse } from 'axios';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import http from '@/services/http.service';
import { SellingPoint } from '../entities/selling-point.entity';

class SellingPointsService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: SellingPoint) {
    return http.post('/selling-point', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: SellingPoint) {
    return http
      .put(`/selling-point/${payload.id}`, payload)
      .then(({ data }: AxiosResponse) => data);
  }

  public delete(sellingPointId: string) {
    return http.delete(`/selling-point/${sellingPointId}`).then(({ data }: AxiosResponse) => data);
  }

  public getAllSellingPoints() {
    return http.get('/selling-point').then(({ data }: AxiosResponse) => data.data);
  }

  public getAllSellingPointsPaginated() {
    return http
      .get('/selling-point', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getSellingPointById(id: string) {
    return http.get(`/selling-point/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public getSellingPointByCompanyId(companyId: string) {
    return http
      .get('/selling-point', {
        params: {
          company_id: companyId,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }
}

const simplePaginationService = new SimplePagination();
export default new SellingPointsService(simplePaginationService);
