
import { Component, Vue, Prop } from 'vue-property-decorator';
import Chart from 'chart.js';
import ChartDashboard from '../entities/charts/chart-dashboard.entity';
import ClientsByUserChartDialog from './dialogs/clients-by-user-dialog.chart.vue';

@Component({
  components: {
    ClientsByUserChartDialog,
  },
})
export default class ClientsByUser extends Vue {
  public $refs: any;

  @Prop({ type: Object, default: () => ({}) })
  private content!: ChartDashboard;

  @Prop({ type: String, default: () => '' })
  private companyId!: string;

  public showDialog: boolean = false;

  private setShowDialog() {
    this.showDialog = true;
  }

  private get hasData() {
    return !!this.content.labels && !!this.content.labels.length;
  }

  private createChart() {
    const ctx = this.$refs.chart;
    const data = {
      labels: this.content.labels,
      datasets: [
        {
          barPercentage: 0.25,
          backgroundColor: '#3326ae',
          data: this.content.values,
        },
      ],
    };

    const options = {
      responsive: true,
      legend: {
        display: false,
        labels: {
          fontColor: 'rgba(0,0,0, 0.5)',
        },
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    // const ctx = document.getElementById('statisticsChart').getContext('2d');
    const myLineChart = new Chart(ctx, {
      type: 'bar',
      data,
      options,
    });
  }

  private mounted() {
    this.createChart();
  }
}
