import IDashboardAdapter from '../../interfaces/dashboard-chart-adapter.interface';

export class ClientCreatedBySellingPointAdapter implements IDashboardAdapter {
  private getLabels(data: any[]): string[] {
    return data.map((it: any) => it.selling_point_name);
  }

  private getTotalValue(data: any[] = []): number {
    const total = data.reduce((acc, it) => {
      acc += it.value;
      return acc;
    }, 0);
    return total;
  }

  private calculatePercentage(total: number, value: number): number {
    return (value * 100) / total;
  }

  private getValues(data: any[]): number[] {
    // const total = this.getTotalValue(data);
    return data.map((it: any) => it.value);
  }

  public format(data: any[]) {
    const labels = this.getLabels(data);
    const values = this.getValues(data);

    return { labels, values };
  }
}
