
import { Component, Vue, Prop } from 'vue-property-decorator';
import Chart from 'chart.js';
import ChartDashboard from '../../entities/charts/chart-dashboard.entity';
import IDashboard from '../../interfaces/dashboard.interface';
import Dashboard from '../../entities/dashboard.entity';
import { IDashboardFilter } from '../../interfaces/dashboard-filter.interface';
import DashboardFilter from '../../entities/filter/dashboard-filter.entity';
import IChartDashboard from '../../interfaces/chart-dashboard.interface';

@Component
export default class ClientsByUserChartDialog extends Vue {
  @Prop({ type: Boolean, default: () => false })
  private value!: boolean;

  @Prop({ type: String, default: () => '' })
  private companyId!: string;

  private loading: boolean = false;

  private content: IChartDashboard | any = new ChartDashboard();

  private dashboardData: IDashboard = new Dashboard();

  public $refs: any;

  private get hasData() {
    return this.content.labels && this.content.labels.length;
  }

  private changeDialog(data: boolean) {
    if (data === true) return;
    this.$emit('input', false);
  }

  private async getData() {
    const filter: IDashboardFilter = new DashboardFilter({ company_id: this.companyId });
    this.content = await this.dashboardData.getClientsCreatedByUser(filter).catch(() => {
      this.changeDialog(false);
      this.$snackbar.open({
        text: 'Erro ao buscar os dados do dashboard',
        color: 'danger',
        buttonColor: '#fff',
      });
    });
  }

  private async createChart() {
    this.loading = true;
    await this.getData();
    this.loading = false;
    const ctx = this.$refs.chart;
    const data = {
      labels: this.content.labels,
      datasets: [
        {
          barPercentage: 0.25,
          backgroundColor: '#3326ae',
          data: this.content.values,
        },
      ],
    };

    const options = {
      responsive: true,
      legend: {
        display: false,
        labels: {
          fontColor: 'rgba(0,0,0, 0.5)',
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    // const ctx = document.getElementById('statisticsChart').getContext('2d');
    const myLineChart = new Chart(ctx, {
      type: 'bar',
      data,
      options,
    });
  }

  private mounted() {
    this.createChart();
  }
}
