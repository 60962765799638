import { Address } from '@/components/address/entities/address.entity';
import store from '@/store';
import { User } from '../../Users/entities/user.entity';
import sellingPointService from '../services/selling-points.service';

export class SellingPoint {
  public id: string = '';

  public name: string = '';

  public active: boolean | number = true;

  public has_relationship: boolean | number = false;

  public companyId: string = '';

  public company: any;

  public users: User[] = [];

  public address: Address = new Address();

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name || '';
    this.active = data.active === false ? 0 : 1;
    this.companyId = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.company = data.company;
    this.address = new Address(data.address);
    this.has_relationship = data.has_relationship === true;
    this.users = data.users || [];
  }

  private format() {
    return {
      ...this,
      users: this.users.map((user: any) => user.id),
      company_id: this.companyId,
      address: this.address.formatToSave(),
    };
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  public async save() {
    return sellingPointService.create(this.format());
  }

  public async update() {
    return sellingPointService.update(this.format());
  }

  public async delete() {
    return sellingPointService.delete(this.id);
  }
}
