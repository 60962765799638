
import { Component, Vue, Prop } from 'vue-property-decorator';
import Chart from 'chart.js';
import ChartDashboard from '../entities/charts/chart-dashboard.entity';
import ClientsBySellingPointDialogChart from './dialogs/clients-by-selling-point-dialog.chart.vue';

@Component({
  components: {
    ClientsBySellingPointDialogChart,
  },
})
export default class ClientsBySellingPointChart extends Vue {
  public $refs: any;

  @Prop({ type: Object, default: () => ({}) })
  private content!: ChartDashboard;

  @Prop({ type: String, default: () => '' })
  private companyId!: string;

  public showDialog: boolean = false;

  private get hasData() {
    return this.content.labels && this.content.labels.length;
  }

  private setShowDialog() {
    this.showDialog = true;
  }

  private getTotalValue(data: any[] = []): number {
    const total = data.reduce((acc, it) => {
      acc += it;
      return acc;
    }, 0);
    return total;
  }

  private createChart() {
    const ctx = this.$refs.chart;
    const data = {
      labels: this.content.labels,
      datasets: [
        {
          backgroundColor: ['#FF5500', '#dad7e9', '#00bcd4', '#4caf50'],
          data: this.content.values,
        },
      ],
    };

    const options = {
      responsive: true,
      cutoutPercentage: 70,
      backgroundColor: '#fff',
    };

    const plugins = [
      {
        beforeDraw: (chart: any) => {
          const { width, height } = chart.chart;
          const context = chart.chart.ctx;

          context.restore();
          const fontSize = '45px';
          context.font = `${fontSize} sans-serif`;
          context.textBaseline = 'middle';

          const text = this.getTotalValue(this.content.values);
          const textX = Math.round((width - context.measureText(text).width) / 2);
          const textY = height / 1.7;

          context.fillText(text, textX, textY);
          context.save();
        },
      },
    ];

    const myLineChart = new Chart(ctx, {
      type: 'doughnut',
      data,
      options,
      plugins,
    });
  }

  private mounted() {
    this.createChart();
  }
}
