
import { Component, Vue, Prop } from 'vue-property-decorator';
import Chart from 'chart.js';
import ChartDashboard from '../../entities/charts/chart-dashboard.entity';
import IDashboard from '../../interfaces/dashboard.interface';
import Dashboard from '../../entities/dashboard.entity';
import { IDashboardFilter } from '../../interfaces/dashboard-filter.interface';
import DashboardFilter from '../../entities/filter/dashboard-filter.entity';
import IChartDashboard from '../../interfaces/chart-dashboard.interface';

@Component
export default class ClientsBySellingPointDialogChart extends Vue {
  @Prop({ type: Boolean, default: () => false })
  private value!: boolean;

  @Prop({ type: String, default: () => '' })
  private companyId!: string;

  private loading: boolean = false;

  private content: IChartDashboard | any = new ChartDashboard();

  private dashboardData: IDashboard = new Dashboard();

  public $refs: any;

  private get hasData() {
    return this.content.labels && this.content.labels.length;
  }

  private changeDialog(data: boolean) {
    if (data === true) return;
    this.$emit('input', false);
  }

  private async getData() {
    const filter: IDashboardFilter = new DashboardFilter({ company_id: this.companyId });
    this.content = await this.dashboardData.getClientsBySellingPoint(filter).catch(() => {
      this.changeDialog(false);
      this.$snackbar.open({
        text: 'Erro ao buscar os dados do dashboard',
        color: 'danger',
        buttonColor: '#fff',
      });
    });
  }

  private getTotalValue(data: any[] = []): number {
    const total = data.reduce((acc, it) => {
      acc += it;
      return acc;
    }, 0);
    return total;
  }

  private async createChart() {
    this.loading = true;
    await this.getData();
    this.loading = false;
    const ctx = this.$refs.chart;
    const data = {
      labels: this.content.labels,
      datasets: [
        {
          backgroundColor: ['#FF5500', '#dad7e9', '#00bcd4', '#4caf50'],
          data: this.content.values,
        },
      ],
    };

    const options = {
      responsive: true,
      cutoutPercentage: 70,
      backgroundColor: '#fff',
    };

    const plugins = [
      {
        beforeDraw: (chart: any) => {
          const { width, height } = chart.chart;
          const context = chart.chart.ctx;

          context.restore();
          const fontSize = '45px';
          context.font = `${fontSize} sans-serif`;
          context.textBaseline = 'middle';

          const text = this.getTotalValue(this.content.values);
          const textX = Math.round((width - context.measureText(text).width) / 2);
          const textY = height / 1.7;

          context.fillText(text, textX, textY);
          context.save();
        },
      },
    ];

    const myLineChart = new Chart(ctx, {
      type: 'doughnut',
      data,
      options,
      plugins,
    });
  }

  private mounted() {
    this.createChart();
  }
}
