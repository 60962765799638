import IDashboardAdapter from '../../interfaces/dashboard-chart-adapter.interface';

export class ClientCreatedByUserAdapter implements IDashboardAdapter {
  private formatUserName(userName: string = ''): string {
    return userName
      .split(' ')
      .slice(0, 1)
      .join(' ');
  }

  private getLabels(data: any[]): string[] {
    return data.map((it: any) => this.formatUserName(it.user_name));
  }

  private getValues(data: any[]): number[] {
    return data.map((it: any) => it.value);
  }

  public format(data: any[]) {
    const labels = this.getLabels(data);
    const values = this.getValues(data);

    return { labels, values };
  }
}
