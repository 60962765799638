
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import DashboardLoaderComponent from '@/components/loader/dashboard-loader.component.vue';
import NoContentDashboard from './components/no-content-dashboard.component.vue';

import SoldWinnerChart from './dashs/sold-winner.chart.vue';

import ClientsBySellingPointChart from './dashs/clients-by-selling-point.chart.vue';
import ClientsByUser from './dashs/clients-by-user.chart.vue';
import ClientsByEnterpriseChart from './dashs/clients-by-enterprise.chart .vue';
import WinnerRegisterClients from './dashs/winner-register-clients.chart.vue';
import WinnerRegisterClientsByUnitChart from './dashs/winner-register-clients-by-unit.chart.vue';
import DashboardFilterComponent from './components/dashboard-filter.component.vue';
import IDashboard from './interfaces/dashboard.interface';
import Dashboard from './entities/dashboard.entity';
import InterfaceFormatDashboardHandler from './interfaces/format-dashboard-handler.interface';

import { IDashboardFilter } from './interfaces/dashboard-filter.interface';
import DashboardFilter from './entities/filter/dashboard-filter.entity';
import WinnerDashboard from './entities/ranking/winner-dashboard.entity';
import { User } from '../Administrative/modules/Users/entities/user.entity';
import { IDashboardItem } from './interfaces/dashboard-item.interface';
import enterpriseService from '../Administrative/modules/Enterprises/services/enterprise.service';
import Enterprises from '../Administrative/modules/Enterprises/Enterprises.vue';

@Component({
  components: {
    NoContentDashboard,
    DashboardLoaderComponent,
    ClientsByUser,
    DashboardFilterComponent,
    ClientsByEnterpriseChart,
    SoldWinnerChart,
    ClientsBySellingPointChart,
    WinnerRegisterClientsByUnitChart,
    WinnerRegisterClients,
  },
  computed: { ...mapGetters(['user']) },
})
export default class DashboardComponent extends Vue {
  public user!: any;

  private contentOnDashboardIsNull: boolean = true;

  private showLoader: boolean = true;

  private newClient: boolean = false;

  private dashboardData: IDashboard = new Dashboard();

  private filter: IDashboardFilter = new DashboardFilter();

  private soldWinner: any | WinnerDashboard = {};

  private setIntervalReference: any;

  private soldWinnerUnit: any | WinnerDashboard = {};

  private winnerRegisterClients: any | WinnerDashboard = {};

  private clientsByUser = {};

  private clientsByEnterprise = {};

  private cliensBySellingPoint = {};

  private active: number = 0;

  private setActiveChart(active: number) {
    this.active = active;
  }

  private goToEnterprise() {
    this.$router.push({ name: 'enterprise-create' });
  }

  private async search() {
    this.$loader.open();
    clearInterval(this.setIntervalReference);
    await this.getData();
    this.autoReload();
    this.$loader.close();
  }

  private async setCompanyId(companyId: string) {
    this.filter.company_id = companyId;
    if (companyId) {
      await this.getData();
    }
  }

  private hasData(data: IDashboardItem) {
    return data.contentIsNull ? data.contentIsNull() === false : false;
  }

  private checkDataOnDashboard() {
    const contentWinners = [
      this.soldWinner,
      this.winnerRegisterClients,
      this.soldWinnerUnit,
      this.clientsByUser,
      this.clientsByEnterprise,
      this.cliensBySellingPoint,
    ];
    return contentWinners.every((it: WinnerDashboard) => (it.contentIsNull ? it.contentIsNull() : false));
  }

  private async getData() {
    this.soldWinner = await this.dashboardData.getSoldWinnerData(this.filter);
    this.winnerRegisterClients = await this.dashboardData.getWinnerRegisterClients(this.filter);
    this.soldWinnerUnit = await this.dashboardData.getWinnerRegisterClientsByUnit(this.filter);
    this.clientsByUser = await this.dashboardData.getClientsCreatedByUser({
      ...this.filter,
      take_limit: 5,
    });
    this.clientsByEnterprise = await this.dashboardData.getClientCreatedByEnterprise({
      ...this.filter,
      take_limit: 5,
    });
    this.cliensBySellingPoint = await this.dashboardData.getClientsBySellingPoint({
      ...this.filter,
      take_limit: 5,
    });
    this.contentOnDashboardIsNull = this.checkDataOnDashboard();
  }

  private autoReload() {
    if (this.contentOnDashboardIsNull) return;
    this.setIntervalReference = setInterval(async () => {
      // this.$loader.open();
      await this.getData();
      // this.$loader.close();
    }, 300000);
  }

  private async getEnterprises() {
    const companyId = this.filter.company_id || this.user.company_id;
    const enterprises: Enterprises[] = await enterpriseService.getEnterpriseByCompanyId(companyId);
    this.newClient = enterprises.length === 0;
  }

  private beforeDestroy() {
    clearInterval(this.setIntervalReference);
  }

  private async created() {
    this.showLoader = true;
    await this.setCompanyId(this.user.company_id);
    this.contentOnDashboardIsNull = this.checkDataOnDashboard();
    this.showLoader = false;
    this.autoReload();
  }
}
