import IDashboardAdapter from '../../interfaces/dashboard-chart-adapter.interface';

export class ClientWinnerAdapter implements IDashboardAdapter {
  private getOnlyFirstName(name: string) {
    return name
      .split(' ')
      .slice(0, 3)
      .join(' ');
  }

  format(data: any) {
    return {
      ...data,
      user_name: this.getOnlyFirstName(data.user_name),
    };
  }
}
