
import { mapGetters } from 'vuex';

import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from '@/services/moment/moment.service';
import { Company } from '@/modules/Administrative/modules/Companies/entities/company.entity';
import sellingPointsService from '@/modules/Administrative/modules/SellingPoints/services/selling-points.service';
import { SellingPoint } from '@/modules/Administrative/modules/SellingPoints/entities/selling-point.entity';
import companiesService from '@/modules/Administrative/modules/Companies/services/companies.service';
import DashboardFilter from '../entities/filter/dashboard-filter.entity';
import { IDashboardFilter } from '../interfaces/dashboard-filter.interface';

@Component({ computed: { ...mapGetters(['user']) } })
export default class DashboardFilterComponent extends Vue {
  @Prop({ type: Object, default: () => new DashboardFilter() })
  private filter!: IDashboardFilter;

  private user!: any;

  private sellingPoints: SellingPoint[] = [];

  private companies: Company[] = [];

  private years: number[] = [];

  private months: any[] = [
    { name: 'Janeiro', value: '01' },
    { name: 'Fevereiro', value: '02' },
    { name: 'Março', value: '03' },
    { name: 'Abril', value: '04' },
    { name: 'Maio', value: '05' },
    { name: 'Junho', value: '06' },
    { name: 'Julho', value: '07' },
    { name: 'Agosto', value: '08' },
    { name: 'Setembro', value: '09' },
    { name: 'Outubro', value: '10' },
    { name: 'Novembro', value: '11' },
    { name: 'Dezembro', value: '12' },
  ];

  private async getSellingPoints() {
    const companyId = this.user.company_id || this.filter.company_id;
    const sellingPoints: any[] = await sellingPointsService.getSellingPointByCompanyId(companyId);
    this.sellingPoints = sellingPoints.map((it: any) => new SellingPoint(it));
  }

  private getLastYears(quantity: number): number[] {
    const year = new Date().getFullYear();
    return Array.from({ length: quantity }, (v, i) => year - quantity + i + 1).reverse();
  }

  private setCompanyId(companyId: string) {
    this.$emit('set-company-id', companyId);

    this.getSellingPoints();
  }

  private async getCompanies() {
    const companies: Company[] = await companiesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  private search() {
    this.$emit('search');
  }

  private created() {
    if (this.$permissionAccess.group.isSuperAdmin) {
      this.getCompanies();
    }
    this.years = this.getLastYears(4);
    this.getSellingPoints();
  }
}
