import store from '@/store';
import moment from '@/services/moment/moment.service';
import { IDashboardFilter } from '../../interfaces/dashboard-filter.interface';

export default class DashboardFilter implements IDashboardFilter {
  public selling_points: string[];

  public years: string[];

  public months: string[];

  public company_id: string;

  public take_limit: number;

  constructor(data: IDashboardFilter | any = {}) {
    this.selling_points = data.selling_points;
    this.years = data.years || [Number(moment().format('YYYY'))];
    this.months = data.months || [
      moment().format('MM'),
      moment()
        .subtract(1, 'month')
        .format('MM'),
    ];
    this.take_limit = data.take_limit;
    this.company_id = data.company_id || store.getters.user.company_id;
  }
}
