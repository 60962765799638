import IDashboardAdapter from '../../interfaces/dashboard-chart-adapter.interface';

export class ClientWinnerCurrencyAdapter implements IDashboardAdapter {
  private formatToCurrency(value: number) {
    return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  format(data: any) {
    return {
      ...data,
      value: this.formatToCurrency(data.value),
    };
  }
}
