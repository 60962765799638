import { IDashboardFilter } from '../interfaces/dashboard-filter.interface';
import IDashboard from '../interfaces/dashboard.interface';
import ClientsCreatedByEnterpriseService from '../services/clients-by-enterprise.service ';
import ClientsCreatedBySellingPointService from '../services/clients-by-selling-point.service';
import ClientsByUserService from '../services/clients-by-user.service';
import ClientsCreatedWinnerService from '../services/clients-created-winner.service';
import SoldWinnerUnitService from '../services/sold-winner-unit.service';
import SoldWinnerService from '../services/sold-winner.service';
import ChartDashboard from './charts/chart-dashboard.entity';
import { ClientCreatedByEnterpriseAdapter } from './charts/client-created-by-enterprise.adapter';
import { ClientCreatedBySellingPointAdapter } from './charts/client-created-by-selling-point.adapter';
import { ClientCreatedByUserAdapter } from './charts/client-created-by-user.adapter';
import { ClientWinnerCurrencyAdapter } from './ranking/client-winner-currency.adapter';

import { ClientWinnerAdapter } from './ranking/client-winner.adapter';

import WinnerDashboard from './ranking/winner-dashboard.entity';

export default class Dashboard implements IDashboard {
  public async getSoldWinnerData(filter: IDashboardFilter) {
    const adapter = new ClientWinnerCurrencyAdapter();
    const data = await new SoldWinnerService(adapter).getData(filter);
    return new WinnerDashboard(data);
  }

  public async getWinnerRegisterClients(filter: IDashboardFilter) {
    const adapter = new ClientWinnerAdapter();
    const data = await new ClientsCreatedWinnerService(adapter).getData(filter);
    return new WinnerDashboard(data);
  }

  public async getWinnerRegisterClientsByUnit(filter: IDashboardFilter) {
    const adapter = new ClientWinnerAdapter();
    const data = await new SoldWinnerUnitService(adapter).getData(filter);
    return new WinnerDashboard(data);
  }

  public async getClientsCreatedByUser(filter: IDashboardFilter) {
    const adapter = new ClientCreatedByUserAdapter();
    const data = await new ClientsByUserService(adapter).getData(filter);
    return new ChartDashboard(data);
  }

  public async getClientCreatedByEnterprise(filter: IDashboardFilter) {
    const adapter = new ClientCreatedByEnterpriseAdapter();
    const data = await new ClientsCreatedByEnterpriseService(adapter).getData(filter);
    return new ChartDashboard(data);
  }

  public async getClientsBySellingPoint(filter: IDashboardFilter) {
    const adapter = new ClientCreatedBySellingPointAdapter();
    const data = await new ClientsCreatedBySellingPointService(adapter).getData(filter);
    return new ChartDashboard(data);
  }
}
