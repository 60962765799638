import IChartDashboard from '../../interfaces/chart-dashboard.interface';

export default class ChartDashboard implements IChartDashboard {
  public labels: string[];

  public values: number[];

  constructor(data: any = {}) {
    this.labels = data.labels || [];
    this.values = data.values || [];
  }

  public contentIsNull() {
    return !this.labels || !this.labels.length;
  }
}
