import { AxiosResponse } from 'axios';
import http from '@/services/http.service';
import IDashboardService from '../interfaces/dashboard-service.interface';
import { IDashboardFilter } from '../interfaces/dashboard-filter.interface';
import IDashboardAdapter from '../interfaces/dashboard-chart-adapter.interface';

export default abstract class AbstractDashboardService implements IDashboardService {
  private adapter: IDashboardAdapter;

  constructor(adapter: IDashboardAdapter) {
    this.adapter = adapter;
  }

  abstract createUrl(): string;

  getData(filter: IDashboardFilter): Promise<any> {
    const url = this.createUrl();
    return http
      .get(url, { params: filter })
      .then(({ data }: AxiosResponse) => this.adapter.format(data.data));
  }
}
