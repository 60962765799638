import IDashboardAdapter from '../../interfaces/dashboard-chart-adapter.interface';

export class ClientCreatedByEnterpriseAdapter implements IDashboardAdapter {
  private getLabels(data: any[]): string[] {
    return data.map((it: any) => {
      if (it.enterprise_name && it.enterprise_name.length > 15) {
        const limited = it.enterprise_name.slice(0, 15);
        return `${limited}...`;
      }
      return it.enterprise_name;
    });
  }

  private getValues(data: any[]): number[] {
    return data.map((it: any) => it.value);
  }

  public format(data: any[]) {
    const labels = this.getLabels(data);
    const values = this.getValues(data);

    return { labels, values };
  }
}
